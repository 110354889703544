<template>

  <AppMenu/>
  <PaymentModal ref="payment_modal" @open="openModal" @success="reload_data()"/>
  <div class="content-wrapper">
      <div class="content">
        <div class="title"> Evidencia platieb <div style="float:right; width: 600px;">
          <VueDatePicker v-model="date" placeholder="Platby" range style="width: 370px; margin-top: 2px;display: block-inline; float: left; margin-right: 10px;" :enable-time-picker="false" format="MM/dd/yyyy"></VueDatePicker>
          <input type="text" placeholder="Vyhladavanie..." name="search" v-model="search"/> <i @click="openModal({})" class="fas-custom fas fa-plus"></i></div></div>

          <table>
            <thead>
                <tr>
                    <th>Datum Pridania</th>
                    <th>Autor</th>
                    <th>Hodnota</th>
                    <th>Zmluva</th>
                    <th>Akcie</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in paginatedData" :key="index">
                    <td>{{item.date}}</td>
                    <td>{{item.user.name}}</td>
                    <td>{{item.value}}€</td>
                    <td v-if="item.contract && Object.keys(item.contract).length !== 0"><a target="_blank" :href="'contracts/'+item.contract.id">{{item.contract.contract_number}} | {{item.client.name}} {{item.client.surname}}</a></td>
                    <td v-else>Jednorazova platba</td>
                    <td style="text-align:center;"><i @click="openModal(item)" class="fas fa-pen"></i> | <i @click="deletePayment(item.id)" class="fas fa-trash-alt"></i></td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-controls">
          <a @click="prevPage" v-if="currentPage !== 1" class="pagination-button"><i class="fa-solid fa-angle-left"></i></a>
          <span class="pagination-info">Stranka {{ currentPage }} z {{ totalPages }} Zaznamov: {{filteredData.length}}</span>
          <a @click="nextPage" v-if="currentPage !== totalPages" class="pagination-button"><i class="fa-solid fa-angle-right"></i></a>
        </div>
      </div>
  </div>

</template>

<script>

import AppMenu from './AppMenu.vue'
import PaymentModal from './Modals/PaymentModal.vue';
import PaymentService from '@/services/payment'
import { ref } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const date = ref();


export default {
  name: 'PaymentList',
  components: {
    AppMenu,
    VueDatePicker,
    PaymentModal
  },
  data: function () {
    return {
      search: "",
      map: {
        "CO": "Standard"
      },
      state_translate : {
        "1": "Active",
        "2": "Paused",
        "3": "Canceled"
      },
      date: "",
      payments: [],
      newPayment: true,
      currentPage: 1,
      itemsPerPage: 10,
      sortOrder: 'desc',
    }
  },
  methods: {
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    openModal(item) {
          this.$refs.payment_modal.openModal(item);
    },
    formatDate(date) {
      if (!date) return null;
      date = new Date(date);
      const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return localDate.toISOString().split('T')[0];
    },
    reload_data(){
      PaymentService.getPayments()
        .then(response => {
            this.payments = response.data.data
            console.log(this.payments)
        })
        .catch(() => {
          localStorage.setItem('token', "");
          this.$router.push('/');
        });
    },
    deletePayment(id){
      PaymentService.deletePayment(id)
        .then(() => {
          toast("Platba uspesne zmazana", {
                        autoClose: 5000,
          });
          this.reload_data();
        })
        .catch(() => {
          localStorage.setItem('token', "");
          this.$router.push('/');
        });
    },
  },
  mounted() {
      this.reload_data()

      date.value = [];
  },
  computed: {
    filteredData() {
      return this.payments.filter(item => {
            if ((!this.date || this.date.length !== 2) && !this.search) {
             return true;
           }
          if (this.date) {
            const [startDate, endDate] = this.date;
            const date = this.formatDate(new Date(item.date));

            console.log(this.formatDate(startDate))
            console.log(this.formatDate(endDate))
            console.log(this.formatDate(startDate) <= date)
            console.log(this.formatDate(endDate) >= date)
            if (date >= this.formatDate(startDate) && date <= this.formatDate(endDate))
              return true;
          }
          if (JSON.stringify(item).toLowerCase().includes(this.search.toLowerCase()) && this.search)
              return true;
      });
    },
    sortedData() {
      // Create a new array by spreading `filteredData`
      const sortedArray = [...this.filteredData].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        if (this.sortOrder === 'asc') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
      return sortedArray;
    },
    totalPages() {
      return Math.ceil(this.sortedData.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.sortedData.slice(start, end);
    }
  },
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-info {
  margin: 0 10px;
  font-size: 16px;
  color: #333333;
}


a {
  font-weight: 700;
  cursor: pointer;
  color: #2c3e50;
  text-decoration: none;
}

a:hover {
  color: #369af1;
}

input[type="text"] {
  padding: 8px;
  padding-left: 15px;

  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  margin-right: 10px;
  margin-top: -1px;
}

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}
table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    margin-bottom: 50px;
}

table, th, td {
    border: 1px solid black;
}

th, td {
    padding: 8px;
    text-align: left;
}

thead {
    background-color: #79baf4;
}

tr:nth-child(even) {background: #dfdfdf}


.fas {
    color: #369af1;
    color: #8f8f8f;
    transition: color 0.3s ease;
    cursor: pointer;
}

.fas-custom {
    padding:0;
    margin:0;
    font-size: 16px;
}

.fas:hover {
    color: #8f8f8f;
        color: #369af1;
}

</style>
