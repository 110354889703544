import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from './components/UserLogin.vue';
import ClientData from './components/ClientData.vue';
import ClientList from './components/ClientList.vue';
import ClientDetails from './components/ClientDetails.vue';
import NewClient from './components/NewClient.vue';
import NewContract from './components/NewContract.vue';
import ContractList from './components/ContractList.vue';
import ContractDetails from './components/ContractDetails.vue';
import PaymentList from './components/PaymentList.vue';
import EntryList from './components/EntryList.vue';


const routes = [
  { path: '/', component: UserLogin },
  { path: '/clients', component: ClientList },
  { path: '/new-client', component: NewClient },
  { path: '/new-contract', component: NewContract },
  { path: '/contracts', component: ContractList },
  { path: '/contracts/:id', component: ContractDetails, props: true },
  { path: '/client/:id', component: ClientDetails, props: true },
  { path: '/payments', component: PaymentList },
  { path: '/entries', component: EntryList },
  { path: '/client', component: ClientData }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
