<template>
<div class="bg-image">
  <div class="overlay"></div>
    <div class="menu">
        <h2><i class="fa-regular fa-user"></i><div style="display: inline-block; position: relative;left:10px;">MF Fitness</div></h2>
<h2>
        <label class="switch">
<input type="checkbox" v-model="isSwitchOn" @change="handleSwitchChange">
<span class="slider"></span>
</label><div style="display: inline-block; position: relative;left:10px; top: -4px; font-size: 16px;">Zobrazovat profily</div>
</h2>
          <li><i class="fa-solid fa-minus"></i><div class="text">Administrator</div></li>
        <ul class="menu-content">
          <a href="/contracts"><i class="fa-solid fa-file-contract"></i><div class="text">Zmluvy</div></a>
          <a href="/payments"><i class="fa-solid fa-credit-card"></i><div class="text">Platby</div></a>
          <a href="/clients"><i class="fa-solid fa-user-group"></i><div class="text">Členovia</div></a>
          <a href="/entries"><i class="fa-solid fa-door-open"></i><div class="text">Check-in</div></a>
          <a href="/new-contract"><i class="fa-solid fa-file-circle-plus"></i><div class="text">Nové Členstvo</div></a>
        </ul>
        <!--
        <li><i class="fa-solid fa-minus"></i><div class="text" >Spravca</div></li>
        <ul class="menu-content">
          <a href="/contracts"><i class="fa-solid fa-file-contract"></i><div class="text">Zmluvy</div></a>
          <a href="/payments"><i class="fa-solid fa-credit-card"></i><div class="text">Platby</div></a>
          <a href="/clients"><i class="fa-solid fa-user-group"></i><div class="text">Klienti</div></a>
          <a href="/entry"><i class="fa-solid fa-door-open"></i><div class="text">Vstupy</div></a>
        </ul>
      -->
      <!--
        <li><i class="fa-solid fa-minus"></i><div class="text">Moje Rozhranie</div></li>
        <ul class="menu-content">
          <a href="/contracts"><i class="fa-solid fa-file-contract"></i><div class="text">Moja Zmluva</div></a>
          <a href="/payments"><i class="fa-solid fa-credit-card"></i><div class="text">Moje Platby</div></a>
          <a href="/clients"><i class="fa-solid fa-user"></i><div class="text">Moje Informacie</div></a>
          <a href="/entry"><i class="fa-solid fa-door-open"></i><div class="text">Moje Vstupy</div></a>
        </ul>
       <li>Pouzivatel</li>
        <ul class="menu-content">
          <li>Zmluva</li>
          <li>Platby</li>
          <li>Osobne informacie</li>
          <li>Vstupy</li>
        </ul>
      -->
        <div  v-if="open" class="footer">
          Copyright © {{ currentYear }} Meno Gymu
        </div>
    </div>
    <div v-if="showModal && isSwitchOn" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h2><p> {{ client.name }} {{ client.surname }}</p></h2>
        <div v-if="client.photo" class="photo" :style="{'background-image': 'url(https://api.mffitness.sk/'+client.photo+')', 'background-size': 'cover'}"></div>
        <div v-else> ZIADNA FOTKA!!! </div>
        <div v-if="client.contracts && client.contracts.at(-1).state === 1" style="float: left; width: calc(100% - 275px); background: green;">
          <h1 style="margin-left: 20px;text-align: left; font-size: 52px;">Vstup povolený</h1>
        </div>
        <div v-else style="width: calc(100% - 275px); background: red; float: left;">
          <h1 style="margin-left: 20px;text-align: left; font-size: 52px;">Vstup pozastavený</h1>
        </div>
        <div style="clear: both;"></div>
      </div>
    </div>
  </div>
</template>

<script>

import ClientService from '@/services/client'

export default {
  name: 'AppMenu',
  data() {
    return {
      currentYear: new Date().getFullYear(),
      open: false,
      messages: [],
      showModal: false,
      currentMessage: '',
      isSwitchOn: false,
      pingInterval: null,
      client: {},
    };
  },
  created() {
    this.connectToSSE();
  },

  methods: {
    connectToSSE() {
      if (!this.isSwitchOn)
        return;

      const clientId = 'client_' + Math.random().toString(36).substring(2, 15);

      // Connect to the SSE endpoint with the unique client ID
      let eventSource = new EventSource(`https://api.mffitness.sk/sse?client_id=${clientId}`);

      eventSource.onmessage = (event) => {
        console.log('New message:', event.data);
        this.messages.push(event.data);
        this.currentMessage = event.data; // Set the current message
        ClientService.getClient(this.currentMessage)
          .then(response => (this.client = response.data.data))
          .catch(() => {
            localStorage.setItem('token', "");
            this.$router.push('/');
          });
        this.showModal = true; // Show the modal
      };

      // Send a ping every 10 seconds to keep the connection alive
      setInterval(() => {
          fetch('https://api.mffitness.sk/sse/ping', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ client_id: clientId }),
          }).then(response => response.json())
            .then(data => console.log('Ping response:', data))
            .catch(error => console.error('Ping error:', error));
      }, 10000);

      // Close SSE connection when the page unloads (navigates away)
      window.addEventListener('beforeunload', () => {
          eventSource.close();
      });
    },
    closeModal() {
      this.showModal = false;
    },
    handleSwitchChange() {
      console.log('Switch state changed:', this.isSwitchOn);
      this.connectToSSE();
      // Add any additional logic you want to execute when the switch state changes
    },

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.photo {
    background-size: 100px 100px;
    background-position: center center;
    background-repeat: no-repeat;
    float: right;
    width:250px;
    height:250px;

    border: 1px solid;
    border-color: black;
    cursor: pointer;
}
/* Smaller Switch styling */
.switch {
  position: relative;
  display: inline-block;
  width: 40px; /* Smaller width */
  height: 20px; /* Smaller height */
  margin-left: -12px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: background-color 0.3s;
  border-radius: 20px; /* Adjusted for smaller switch */
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px; /* Smaller circle */
  width: 14px; /* Smaller circle */
  left: 3px; /* Adjusted for smaller switch */
  bottom: 3px; /* Adjusted for smaller switch */
  background-color: white;
  transition: transform 0.3s; /* Ensure smooth movement */
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50; /* Green when ON */
}

input:checked + .slider:before {
  transform: translateX(20px); /* Adjusted translate value to move the circle correctly */
}

.modal {
  display: block; /* Show the modal */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5); /* Modal background */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Modal width */
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: white;
}

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

.hide {
  opacity: 1;
}
.menu i {
  display: inline-block;
  text-align: center;
}
.menu .text {
  display: inline-block;
  margin-left: 17px;
}

.menu-content i {
  display: inline-block;
  font-size: 24px;
  padding-left: 11px;
  padding-right: 12px;
  width: 30px;
  text-align: center;
}

.menu-content a {
  padding: 12px 0;
  text-decoration: none;
  display: block;
  width: 220px;
  transition: background-color 0.3s ease; /* Smooth transition for hover */
  cursor: pointer;
  font-size: 16px;
  position: relative;
}

.menu-content .text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 52px;
}

/* Styling for each item in the dropdown */
.menu-content li {

}

/* Change background color of dropdown items on hover */
.menu-content a:hover {
  background-color: #369af1; /* Light grey background on hover */
}

.footer {
  position: absolute;
  font-size: 12px;
  text-align: center;
  bottom: 20px;
  width: 220px;
}

.bg-image {
  background-image: url('../assets/bg.jpg');
  background-size: cover;
  background-position: center;
  position: absolute;
  height: 100%;
  width: 100%;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
}

h2 {
    margin: 20px 5px;
    font-size: 20px;
    font-weight: 700;
}

h1 {
    font-size: 64px;
    font-weight: 800;
    margin: 0;
    margin-left: 220px;
    padding: 20px 0;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.3);
    text-transform: uppercase;
    text-align: center;
}

li, ul {
  text-decoration: none;
  list-style-type: none;
}

.logo {
  position:absolute;
  width: auto;
  left:0;
  right: 0;
  margin:auto;
  z-index:1;
  text-align: center;
  color: white;
}

.menu {
    position: absolute;
    min-height: 100%;
    background-color: #369af1;
    width: 50px;
    font-family: 'Open Sans', sans-serif;
    padding: 0;
    z-index:999;
    color: white;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 15px rgba(66,66,66, .17);
    -webkit-transition: width 0.1s ease-in-out;
    -moz-transition: width 0.1s ease-in-out;
    -o-transition: width 0.1s ease-in-out;
    transition: width 0.1s ease-in-out;
}

.menu h2 {
    white-space: nowrap;
    overflow:hidden;
}
.menu:hover {
    width: 220px;
    -webkit-transition: width 0.1s ease-in-out;
    -moz-transition: width 0.1s ease-in-out;
    -o-transition: width 0.1s ease-in-out;
    transition: width 0.1s ease-in-out;
}
.menu li {
    white-space: nowrap;
    overflow:hidden;
    padding-left: 19px;
}
.menu h2 {
    padding-left: 12px;
    text-shadow: none;
    box-shadow: none;
}

.menu-content {
  display: block; /* Hidden by default */
  background-color: #4ca3f0;
  border-radius: 5px; /* Rounded corners */
  overflow: hidden; /* Ensures rounded corners */
  z-index: 1;
  margin-top: 5px; /* Slight space between dropdown and menu */
  padding-left: 0px;

  box-shadow: 0px 0px 15px rgba(66,66,66, .08);
}



</style>
