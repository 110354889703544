<template>

  <AppMenu/>
  <ClientModal ref="client_modal" @open="openModal" @success="reload_data()"/>
  <ContractModal ref="contract_modal" @open="openModal" @success="reload_data()"/>
  <PaymentModal ref="payment_modal" @open="openModal" @success="reload_data()"/>
  <div class="content-wrapper">
      <div class="content" v-if="contract.client">
          <span class="actions"> <i @click="openModal(contract, 'contract')" class="fas fa-file-pen"></i> | <span  v-if="contract.contract_file"><a :href="'https://api.mffitness.sk'+contract.contract_file" target="_blank" class="fas"><i class="fas fa-file-pdf"></i></a> | </span><i class="fas fa-play" @click="updateContract(contract, 1)"></i> | <i class="fas fa-pause" @click="updateContract(contract, 2)"></i> | <i class="fas fa-stop" @click="updateContract(contract, 3)"></i> | <i class="fas fa-trash-alt" @click="deleteContract(contract.id)"></i></span>

          <div class="title"> Zmluva cislo: {{ contract.contract_number }} </div>
          <h2>Informacie o zmluve</h2>
          <table>
            <tbody>
                <tr>
                    <td>Klient</td>
                    <td><a :href="'/client/'+contract.client.id">{{ contract.client.name }} {{ contract.client.surname }} - {{contract.client_id}}</a></td>
                </tr>
                <tr>
                    <td>Typ Clenstva</td>
                    <td>{{map[contract.contract_name]}} - {{contract.contract_number}}</td>
                </tr>
                <tr>
                    <td>Datum Podpisu</td>
                    <td>{{contract.sign_date}}</td>
                </tr>
                <tr>
                    <td>Datum zaciatku</td>
                    <td>{{contract.start_date}}</td>
                </tr>
                <tr>
                    <td>Datum Konca</td>
                    <td>{{contract.end_date}}</td>
                </tr>
                <tr>
                    <td>Stav Clenstva</td>
                    <td>{{state_translate[contract.state]}}</td>
                </tr>
                <tr>
                    <td>Zmluva</td>
                    <td><a :href="'https://api.mffitness.sk'+contract.contract_file">PDF Zmluva {{contract.contract_number}}</a></td>
                </tr>
            </tbody>
        </table>

        <div v-if="contract.payments && Object.keys(contract.payments).length !== 0" >
          <div style="margin-top: 15px;"> <h2 style="float:left">Platby</h2> <div style="float:right; width: 600px; margin-top: 25px;">
            <VueDatePicker v-model="date" placeholder="Platby" range style="width: 370px; margin-top: -2px;display: block-inline; float: left; margin-right: 10px;" :enable-time-picker="false" format="MM/dd/yyyy"></VueDatePicker>
            <input style="margin-bottom: 15px;" type="text" placeholder="Vyhladavanie..." name="search" v-model="search"/></div></div>

          <table>
            <thead>
                <tr>
                    <th>Datum Pridania</th>
                    <th>Autor</th>
                    <th>Hodnota</th>
                    <th>Akcie</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in paginatedData" :key="index">
                    <td>{{item.date}}</td>
                    <td>{{item.user.name}}</td>
                    <td>{{item.value}}€</td>
                    <td style="text-align:center;"><i @click="openModal(item, 'payment')" class="fas fa-pen"></i> | <i @click="deletePayment(item.id)" class="fas fa-trash-alt"></i></td>
                </tr>
            </tbody>
          </table>
          <div class="pagination-controls">
            <a @click="prevPage" v-if="currentPage !== 1" class="pagination-button"><i class="fa-solid fa-angle-left"></i></a>
            <span class="pagination-info">Stranka {{ currentPage }} z {{ totalPages }} Zaznamov: {{paginatedData.length}}</span>
            <a @click="nextPage" v-if="currentPage !== totalPages" class="pagination-button"><i class="fa-solid fa-angle-right"></i></a>
          </div>
        </div>
        <div v-else>
          <p>Neboli obdrzane ziadne platby</p>
        </div>
      </div>
  </div>

</template>

<script>

import AppMenu from './AppMenu.vue'
import ClientModal from './Modals/ClientModal.vue';
import ContractModal from './Modals/ContractModal.vue';
import ContractService from '@/services/contract';
import '@vuepic/vue-datepicker/dist/main.css'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import PaymentService from '@/services/payment'
import PaymentModal from './Modals/PaymentModal.vue';
import VueDatePicker from '@vuepic/vue-datepicker';


export default {
  props: ['id'],
  name: 'ContractDetails',
  components: {
    AppMenu,
    ClientModal,
    ContractModal,
    PaymentModal,
    VueDatePicker,
  },
  data: function () {
    return {
      search: "",
      contract: {},
      date: "",
      currentPage: 1,
      itemsPerPage: 10,
      sortOrder: 'desc',
      map: {
        "CO" : "Mesačné členstvo",
        "COP" : "Polročné členstvo",
        "COY" : "Ročné členstvo"
      },
      state_translate : {
        "1": "Active",
        "2": "Paused",
        "3": "Canceled"
      },
    }
  },
  methods: {
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    openModal(item, modal) {
      if (modal == "client") {
          this.$refs.client_modal.openModal(item.client);
      }
      if (modal == "contract")
          this.$refs.contract_modal.openModal(item);
      if (modal == "payment")
          this.$refs.payment_modal.openModal(item);
    },
    formatDate(date) {
      if (!date) return null;
      date = new Date(date);
      const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return localDate.toISOString().split('T')[0];
    },
    reload_data(){
      ContractService.getContract(this.id)
        .then(response => (this.contract = response.data.data))
        .catch(() => {
          localStorage.setItem('token', "");
          this.$router.push('/');
        });
    },
    updateContract(contract, state){
      contract["state"] = state
      ContractService.updateContract(contract.id, contract)
        .then(() => {
          toast("Kontrakt upravený na: "+this.state_translate[state], {
                        autoClose: 5000,
          });
          this.reload_data();
        })
        .catch(() => {
          localStorage.setItem('token', "");
          this.$router.push('/');
        });
    },
    deleteContract(id){
      ContractService.deleteContract(id)
        .then(() => {
          toast("Kontrakt úspešne zmazaný", {
                        autoClose: 5000,
          });
          this.$router.push('/contracts');
        })
        .catch(() => {
          //localStorage.setItem('token', "");
          //this.$router.push('/');
        });
    },
    deletePayment(id){
      PaymentService.deletePayment(id)
        .then(() => {
          toast("Kontrakt úspešne zmazaný", {
                        autoClose: 5000,
          });
          this.reload_data()
        })
        .catch(() => {
          //localStorage.setItem('token', "");
          //this.$router.push('/');
        });
    },
  },
  mounted() {
      this.reload_data()
  },
  computed: {
    filteredData() {
      return this.contract.payments.filter(item => {
            if ((!this.date || this.date.length !== 2) && !this.search) {
             return true;
           }
          if (this.date) {
            const [startDate, endDate] = this.date;
            const date = this.formatDate(new Date(item.date));

            console.log(this.formatDate(startDate))
            console.log(this.formatDate(endDate))
            console.log(this.formatDate(startDate) <= date)
            console.log(this.formatDate(endDate) >= date)
            if (date >= this.formatDate(startDate) && date <= this.formatDate(endDate))
              return true;
          }
          if (JSON.stringify(item).toLowerCase().includes(this.search.toLowerCase()) && this.search)
              return true;
      });
    },
    sortedData() {
      // Create a new array by spreading `filteredData`
      const sortedArray = [...this.filteredData].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        if (this.sortOrder === 'asc') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
      return sortedArray;
    },
    totalPages() {
      return Math.ceil(this.sortedData.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.sortedData.slice(start, end);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-info {
  margin: 0 10px;
  font-size: 16px;
  color: #333333;
}


.actions {
  color: #aaa;
  float: right;
  font-size: 22px;
  font-weight: bold;
}

input[type="text"] {
  padding: 8px;
  padding-left: 15px;

  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  margin-right: 10px;
  margin-top: -1px;
}

a {
  font-weight: 700;
  cursor: pointer;
  color: #2c3e50;
  text-decoration: none;
}

a:hover {
  color: #369af1;
}

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}
table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
}

table, th, td {
    border: 1px solid black;
}

th, td {
    padding: 8px;
    text-align: left;
}

thead {
    background-color: #79baf4;
}

tr:nth-child(even) {background: #dfdfdf}


.fas {
    color: #8f8f8f;
    transition: color 0.3s ease;
    cursor: pointer;
}

.fas-custom {
    padding:0;
    margin:0;
    font-size: 16px;
}

.fas:hover {
    color: #369af1;
}

</style>
